input:checked + label {
  background: var(--tg-blue);
  color: white;
}

label.package:hover,
label.packag:active,
input:hover + label,
input:active + label {
  background: var(--tg-blue);
  transition: 0.2s;
  color: white;
}
