.Package {
  text-align: center;

  border-radius: 20px;
}

.fav {
  color: #c4e3eb;
}

.stepsbg {
  background-color: var(--tg-blue);
  border-radius: 29px 29px 0px 0px;
  color: white;
  font-weight: bold;
}
.editplanbtn {
  background-color: var(--tg-blue);
  color: white;
  border: 1px solid white;
}
.editplanbtn:hover {
  background-color: white;
  color: var(--tg-blue);
}
.greentxt {
  color: var(--tg-blue);
}
.greenbg {
  background-color: var(--tg-blue);
}
.roundstart {
  border-radius: 29px 29px 0 0;
  background-color: var(--tg-blue);
}
.roundend {
  border-radius: 0 0 29px 29px;
  background-color: var(--tg-blue);
}
.fullround {
  border-radius: 29px;
}
.greenborder {
  border: 2px solid var(--tg-blue);
}

.form {
  background: #0e101c;
  margin: 0 auto;
}

.checkoutinput {
  box-sizing: border-box;

  border-radius: 20px;
  border: 2px solid var(--tg-blue);
  padding: 10px 15px;
  font-size: 14px;
}

.checkoutlabel {
  line-height: 2;
  text-align: left;
  display: block;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 200;
}

.checkoutbtn[type="submit"],
.checkoutinput[type="submit"] {
  background-color: var(--tg-blue);
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 2px;
  margin: auto;
  display: block;
  border-radius: 20px;
  color: white;
  border: 1px solid var(--tg-blue);
}

.checkoutbtn[type="submit"]:hover,
.checkoutinput[type="submit"]:hover {
  background: var(--tg-blue-five);
  /* color: black; */
}

.checkoutbtn[type="submit"]:active,
.checkoutinput[type=".checkoutbtn"]:active,
.checkoutinput[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

.checkoutinput:disabled {
  opacity: 0.4;
}

.checkoutinput[type=".checkoutbtn"]:hover {
  transition: 0.3s all;
}

.checkoutbtn[type="submit"],
.checkoutinput[type=".checkoutbtn"],
.checkoutinput[type="submit"] {
  -webkit-appearance: none;
}

.checkoutbtn[type=".checkoutbtn"] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}
.checkoutbtn {
  display: block;
  appearance: none;
  border: 1px solid #333;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.checkoutselect {
  margin: auto;
  box-sizing: border-box;
  border-radius: 10px;
  border: 0px solid var(--tg-blue);
  padding: 10px 15px;
  font-size: 14px;
  background-color: var(--tg-blue);
  color: white;
  font-size: 17px;
}
