.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 53px;
  background: var(--tg-blue);
  border-radius: 40px;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  margin: auto;
}

.button-element {
  width: 80px;
  height: 40px;
  background: white;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  color: black;
}

#Monthly {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  /* letter-spacing: 2px; */
  color: var(--tg-blue);
}

#Yearly {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  /* letter-spacing: 2px; */
  padding-left: 12px;
  color: white;
}

#One_Time {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 2px;
  padding-left: 12px;
  color: white;
}

@media screen and (min-width: 600px) {
  .button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 370px;
    height: 53px;
    background: var(--tg-blue);
    border-radius: 40px;
    padding-left: 15px;
    padding-right: 15px;
    color: black;
    margin: auto;
  }
  
  .button-element {
    width: 120px;
    height: 40px;
    background: white;
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    color: black;
  }
  
  #Monthly {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    letter-spacing: 2px;
    color: var(--tg-blue);
  }
  
  #Yearly {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 2px;
    padding-left: 12px;
    color: white;
  }
  
  #One_Time {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 2px;
    padding-left: 12px;
    color: white;
  }
}